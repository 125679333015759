import { defineStore } from 'pinia'
import { startStopWatch, stopStopWatch, resetStopWatch } from '@/services/stopWatch'

import { wampCall, wampControllerCall } from '@/services/wamp'

import tunn3lApi from '@/services/api/tunn3l/tunn3lApi'
import notify from '@/services/notify'

import { videoClient } from '@/services/videoClient'

import { useCompetitionStore } from './competitionStore'

interface Point {
  busts: Record<string, boolean>
  time: number
  type: string | null
}

// Utilisation du option store
export const useFormationSkydivingStore = defineStore('formationSkydiving', {
  state: () => ({
    status: null as string | null,
    trigger: null as string | null,
    configuration: {} as any,
    stopwatch: '0.000' as string,

    times: {} as any,
    metas: {} as any,
    startDelay: 0 as number,

    dynamr_flight_id: null as number | null,

    // judges: null as [] | null, // Dans la configuration et en Base ?

    points: null as Point[] | null,
    details: null as [] | null,
    score: null as number | null,
    is_posted: false as boolean,

    // Pour éviter de lancer plusieurs uploads en même temps
    is_uploading_to_tunn3l: false as boolean
  }),
  getters: {
    getBustCount() {
      if (this.details !== undefined && this.details !== null) {
        // const count: number = this.details.filter((busted: boolean) => busted === true).length
        let count = 0
        for (const busted of this.details) {
          if (busted === true) {
            count++
          }
        }
        return count
      }
      return null
    }
  },
  actions: {
    reset() {
      ;(this.status = null),
        (this.trigger = null),
        (this.configuration = {}),
        (this.stopwatch = '0.000'),
        (this.times = {}),
        (this.metas = {}),
        (this.startDelay = 0),
        (this.dynamr_flight_id = null),
        (this.points = null),
        (this.details = null),
        (this.score = null),
        (this.is_posted = false)
    },

    fetchAppStatus(data: any, configuration: any, current: any, status: string, trigger: string) {
      this.status = status
      this.trigger = trigger
      this.configuration = configuration

      this.times = data.times
      this.metas = data.metas
      this.startDelay = data.start_delay

      this.dynamr_flight_id = data.id

      this.points = data.points
      this.details = data.details
      this.score = data.score
      this.is_posted = data.is_posted
    },

    toggleJudgeBust(index: number, who: string) {
      if (this.points) {
        this.points[index].busts[who] = !this.points[index].busts[who]
        this.refreshResults()
        this.save()
      }
    },

    toggleBust(index: number) {
      if (this.points && this.details) {
        for (const who in this.configuration.judges) {
          this.points[index].busts[who] = !this.details[index]
        }
        this.refreshResults()
        this.save()
        // this.points[index].busts = !this.points[index].busts
      }
    },

    setBustType(index: number, value: string | null) {
      if (this.points) {
        this.points[index].type = value
        this.refreshResults()
        this.save()
      }
    },

    calculateAdjustedTime(timeFromVideo: number) {
      if (isNaN(timeFromVideo)) {
        return this.configuration.round_time - 0.01
      }
      return Math.min(
        Math.max(Math.round((timeFromVideo - this.startDelay) * 100) / 100, 0.0),
        this.configuration.round_time - 0.01
      )
    },

    handleAddPoint(adjustedTime: number) {
      if (!this.points) this.points = []
      this.points.push({
        busts: {},
        time: adjustedTime,
        type: null
      })
      this.points.sort((a, b) => a.time - b.time)
      this.refreshResults()
      this.save()
    },

    async addPoint() {
      await videoClient
        .time('relative_work_ng')
        .then((timeFromVideo: number) => {
          const adjustedTime = this.calculateAdjustedTime(timeFromVideo)
          this.handleAddPoint(adjustedTime)
        })
        .catch((error: any) => {
          const defaultTime = this.configuration.round_time - 0.01
          this.handleAddPoint(defaultTime)
          notify(
            'Failed to get video time',
            'setting new point to ' + defaultTime + ' seconds.',
            'info'
          )
        })
    },

    deletePoint(index: number) {
      if (this.points) {
        this.points.splice(index, 1)
        this.refreshResults()
        this.save()
      }
    },

    async videoGotoBegin() {
      await videoClient.goto('relative_work_ng', this.startDelay)
    },

    async videoGotoEnd() {
      await videoClient.goto('relative_work_ng', this.startDelay + this.configuration.round_time)
    },

    async videoGotoPoint(index: number) {
      const value = this.points?.[index]?.time
      if (value) {
        await videoClient.goto(
          'relative_work_ng',
          Math.max(value + this.startDelay, 0)
        )
      }
    },

    judgementIsValid() {
      if (this.details) {
        for (const index in this.details) {
          if (
            this.details[index] === true && !this.points?.[index]?.type || 
            this.details[index] === false && this.points?.[index]?.type
          ) {
            return false
          }
        }
      }
      return true
    },

    save(posted: boolean = false, review: boolean = false) {
      if (!this.judgementIsValid() && posted) {
        // wampControllerCall('relative_work_ng', 'save', {
        //   id: this.dynamr_flight_id,
        //   points: this.points,
        //   has_entry_fault: false,
        //   is_posted: false,
        //   review: review
        // })
        wampCall('sheet.relative_work_ng.save', [
          this.dynamr_flight_id,
          this.points,
          false,
          false,
          review
        ])

        notify('Judgement is not valid', 'Please set bust type for all points', 'info')
        return
      }

      this.is_posted = posted
      if (this.dynamr_flight_id !== null) {
        // const result = wampControllerCall('relative_work_ng', 'save', {
        //   id: this.dynamr_flight_id,
        //   points: this.points,
        //   has_entry_fault: false,
        //   is_posted: this.is_posted,
        //   review: review
        // })
        const result = wampCall('sheet.relative_work_ng.save', [
          this.dynamr_flight_id,
          this.points,
          false,
          this.is_posted,
          review
        ])
        if ('then' in result) {
          result.then(() => {
            if (this.is_posted) {
              const score = useCompetitionStore().getActiveCompetition?.getFlightInfo(this.configuration.provider_flight_id)?.score
              let overwrite = false
              if (score) {
                overwrite = true
              }

              this.publishScores(overwrite).then(() => {
                useCompetitionStore().refreshCompetitionData()
              })

              notify('Score has been posted', '', 'success')
            }
          })
        } else {
          notify('Error saving', (result as { error: string }).error, 'error')
        }
      } else {
        notify('Cannot save: Invalid flight ID', '', 'error')
      }
    },

    refreshResults() {
      const judgeCount = Object.keys(this.configuration.judges).length
      const entry_fault_penalty = this.configuration.entry_fault
        ? this.configuration.entry_fault
        : 0
      const result = wampCall('sheet.relative_work_ng.calculate_result', [
        this.points as Point[],
        judgeCount as number,
        false as boolean, // hasEntryFault
        entry_fault_penalty as number
      ])
      if (result instanceof Promise) {
        result
          .then((response: any) => {
            this.details = response.details
            this.score = response.score
          })
          .catch((error: any) => {
            notify('Error calculating result', error, 'error')
          })
      } else if (typeof result === 'object' && result !== null && 'error' in result) {
        notify('Error calculating result', (result as { error: string }).error, 'error')
      }
    },

    async publishScores(overwrite: boolean) {
      if (this.configuration.event && this.configuration.event.is_tunn3l_linked) {
        this.is_uploading_to_tunn3l = true
        try {
          await tunn3lApi
            .uploadFSattempts(
            this.configuration.event.tunn3l_service.url,
            this.configuration.event.tunn3l_service.auth_token,
            this.configuration.event.tunn3l_service.t3_competition_token,
            this.configuration.event.tunn3l_service.t3_competition_id,
            this.configuration.event.event_id,
            this.configuration.round_id,
            0,
            this.configuration.team.id,
            0,
            0,
            this.points?.length || 0,
            0,
            0,
            'nb_attempts'
          )

          if (overwrite) {
            for (const [index, point] of this.points?.entries() || []) {
              if (point.type) {
                for (const judge of this.configuration.judges_details) {
                  await tunn3lApi.applyBustFS(
                    this.configuration.event.tunn3l_service.url,
                    this.configuration.event.tunn3l_service.auth_token,
                    this.configuration.event.tunn3l_service.t3_competition_token,
                    this.configuration.event.tunn3l_service.t3_competition_id,
                    this.configuration.event.event_id,
                    this.configuration.round_id,
                    0,
                    this.configuration.team.id,
                    judge.id,
                    index + 1,
                    1,
                    0,
                    0,
                    point.type?.toLowerCase() as 'f' | 's' | 'i' | 'g' | 'o'
                  )
                }
              } else {
                for (const judge of this.configuration.judges_details) {
                  await tunn3lApi.removeBustFS(
                    this.configuration.event.tunn3l_service.url,
                    this.configuration.event.tunn3l_service.auth_token,
                    this.configuration.event.tunn3l_service.t3_competition_token,
                    this.configuration.event.tunn3l_service.t3_competition_id,
                    this.configuration.event.event_id,
                    this.configuration.round_id,
                    0,
                    this.configuration.team.id,
                    judge.id,
                    index + 1,
                    0,
                    0,
                    0,
                    0
                  )
                }
              }
            }
          } else {
            for (const [index, point] of this.details?.entries() || []) {
              if (point === true) {
                for (const judge of this.configuration.judges_details) {
                  await tunn3lApi.applyBustFS(
                    this.configuration.event.tunn3l_service.url,
                    this.configuration.event.tunn3l_service.auth_token,
                    this.configuration.event.tunn3l_service.t3_competition_token,
                    this.configuration.event.tunn3l_service.t3_competition_id,
                    this.configuration.event.event_id,
                    this.configuration.round_id,
                    0,
                    this.configuration.team.id,
                    judge.id,
                    index + 1,
                    1,
                    0,
                    0,
                    this.points?.[index].type?.toLowerCase() as 'f' | 's' | 'i' | 'g' | 'o'
                  )
                }
              }
            }
          }

          let message = 'Posted'
          if (overwrite) {
            message = 'Updated'
          }
          notify('Scores uploaded to Tunn3l successfully', message, 'success')

        } catch (error: any) {
          notify('Score upload to Tunn3l failed', error.message, 'error')
        } finally {
          this.is_uploading_to_tunn3l = false
        }
      }
    },

    handleEvent(data: any, status: string, trigger: string) {
      switch (trigger) {
        case 'create':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'activate':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'start':
          if (status === 'running' && this.status === 'active') {
            startStopWatch((time: string) => {
              this.stopwatch = time
            })
          }
          this.points = data.points
          this.details = data.details
          this.score = data.score
          this.status = status
          this.trigger = trigger
          break
        case 'point_validate':
        case 'bust':
          this.status = status
          this.trigger = trigger
          this.points = data.points
          this.details = data.details
          this.score = data.score
          break
        case 'stop':
          this.status = status
          stopStopWatch()
          this.trigger = trigger
          this.times = data.times
          this.metas = data.metas
          this.startDelay = data.start_delay
          this.dynamr_flight_id = data.id
          this.points = data.points
          this.details = data.details
          this.score = data.score
          this.is_posted = data.is_posted
          // Upload scores as unofficial // Maybe later
          // if (this.configuration.event) {
          //   this.publishScores().then(() => {
          //   useCompetitionStore().refreshCompetitionData()
          // })
          // }
          break
      }
    }
  }
})
