let startTime: number = 0
let elapsedTime: number = 0
let timerId: null | number = null // Identifiant du timer
let updateCallback: ((time: string) => void) | null = null

const TICK_INTERVAL = 25

function startStopWatch(update: (time: string) => void) {
  if (timerId === null) {
    startTime = performance.now() - elapsedTime
    updateCallback = update
    timerId = window.setInterval(() => {
      elapsedTime = performance.now() - startTime
      if (updateCallback) {
        updateCallback(formattedTimeStopWatch())
      }
    }, TICK_INTERVAL)
  }
}

function stopStopWatch() {
  if (timerId !== null) {
    clearInterval(timerId)
    timerId = null
    updateCallback = null
  }
}

function resetStopWatch() {
  stopStopWatch()
  startTime = 0
  elapsedTime = 0
}

function formattedTimeStopWatch(): string {
  return (elapsedTime / 1000).toFixed(3)
} // Formate le temps en secondes avec trois décimales

export { startStopWatch, stopStopWatch, resetStopWatch }
