import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { wamp, wampCall } from '@/services/wamp'

import { useSpeedRoundStore } from './speedRoundStore'
import { useFreeRoundStore } from './freeRoundStore'
import { useFormationSkydivingStore } from './formationSkydivingStore'
import { useJudgementStore } from './judgementStore'

// Utilisation du setup store
// préfixe current_ pour les variables qui sont dans le store pour eviter
// les conflits avec les valeurs retournées par wamp
export const useAppStatusStore = defineStore('appStatus', () => {
  const state = ref({
    controller: '' as string,
    status: '' as string,
    trigger: '' as string,
    competition_mode: false as boolean,
    data: {} as any
  })

  // Getter de test en mode setup store
  const stateUpperCase = computed<string>(() => state.value.status.toUpperCase())

  const getColorsBackground = computed<{}>(() => {
    if (state.value.status === 'frozen') {
      return { color1: state.value.data }
    } else if (state.value.status === 'blinking') {
      return { color1: state.value.data.color1, color2: state.value.data.color2 }
    } else return {}
  })

  async function fetchAppStatus() {
    wampCall('app.status')
      .then((res: any) => {
        const { configuration, current, data, mode, name, trigger } = res

        const [controller, status] = name.split('.')

        state.value = {
          controller: controller,
          status: status,
          trigger: trigger,
          competition_mode: mode === 'competition', // Mode can be 'competition' or 'normal',
          // data: data if controller is light
          data: controller === 'light' ? data : {}
        }

        switch (controller) {
          case 'round_speed':
            useSpeedRoundStore().fetchAppStatus(data, configuration, current, status, trigger)
            break
          case 'round_free':
            useFreeRoundStore().fetchAppStatus(data, configuration, current, status, trigger)
            break
          case 'relative_work_ng':
            useFormationSkydivingStore().fetchAppStatus(
              data,
              configuration,
              current,
              status,
              trigger
            )
            break
          case 'judgement_ng':
            useJudgementStore().fetchAppStatus(data, configuration, current, status, trigger)
            break
        }
      })
      .catch((e: Error) => {
        console.error('Fetch app status failed', e)
        throw e
      })
  }

  async function subscribe() {
    try {
      await wamp.subscribe('com.dynamr.event', (eventData: any) => {
        const { argsDict, argsList, details } = eventData
        const { name, data, trigger } = argsDict

        const [controller, status] = name.split('.')

        // reset stores it controller has changed
        if (state.value.controller !== controller) {
          useSpeedRoundStore().reset()
          useFreeRoundStore().reset()
          useFormationSkydivingStore().reset()
          useJudgementStore().reset()
        }

        // Update AppStore values
        state.value.controller = controller
        state.value.status = status
        state.value.trigger = trigger
        state.value.data = controller === 'light' ? data : {}

        switch (controller) {
          case 'round_speed':
            useSpeedRoundStore().handleEvent(data, status, trigger)
            break
          case 'round_free':
            useFreeRoundStore().handleEvent(data, status, trigger)
            break
          case 'relative_work_ng':
            useFormationSkydivingStore().handleEvent(data, status, trigger)
            break
          case 'judgement_ng':
            useJudgementStore().handleEvent(data, status, trigger)
            break
        }
      })
    } catch (e) {
      console.error('Subscription failed', e)
      throw e
    }
  }

  return {
    state,
    stateUpperCase,
    getColorsBackground,
    fetchAppStatus,
    subscribe
  }
})
