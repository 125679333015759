import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

import App from '@/App.vue'
import router from '@/router'

const app = createApp(App)
const pinia = createPinia()

if (!import.meta.env.VITE_DEV) {
  Sentry.init({
    app,
    dsn: 'https://7976ddd2cec934da46be53f8812d0c7c@o553001.ingest.us.sentry.io/4507832992464896',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app.use(pinia)
app.use(router)

app.mount('#app')
