// services/api/isJudging/competitions.ts
import axiosInstance from '@/utils/axios'

const COMPETITIONS_URL = 'competitions/'

export const CompetitionsService = {

  async getCompetitions() {
    const response = await axiosInstance.get(COMPETITIONS_URL)
    if (response.status !== 200) {
      throw new Error('Failed to fetch competitions')
    }
    return response.data
  },

  async getCompetition(id: number) {
    const response = await axiosInstance.get(COMPETITIONS_URL + id)
    if (response.status !== 200) {
      throw new Error('Failed to fetch competition')
    }
    return response.data
  }
}
