import { defineStore } from 'pinia'
import { startStopWatch, stopStopWatch, resetStopWatch } from '@/services/stopWatch'

// Utilisation du option store
export const useFreeRoundStore = defineStore('freeRound', {
  state: () => ({
    status: null as string | null,
    trigger: null as string | null,
    configuration: {} as any,
    stopwatch: '0.000' as string,

    times: {} as any,

    dynamr_flight_id: null as number | null
  }),
  getters: {},
  actions: {
    reset() {
      ;(this.status = null),
        (this.trigger = null),
        (this.configuration = {}),
        (this.stopwatch = '0.000'),
        (this.times = {}),
        (this.dynamr_flight_id = null)
    },
    fetchAppStatus(data: any, configuration: any, current: any, status: string, trigger: string) {
      this.status = status
      this.trigger = trigger
      this.configuration = configuration
    },

    handleEvent(data: any, status: string, trigger: string) {
      // Update store values
      this.status = status

      switch (trigger) {
        case 'create':
          resetStopWatch()
          this.reset()
          this.status = status
          this.trigger = trigger
          this.configuration = data
          this.stopwatch = '0.000'
          break
        case 'activate':
          resetStopWatch()
          this.status = status
          this.trigger = trigger
          this.stopwatch = '0.000'
          break
        case 'start':
          startStopWatch((time: string) => {
            this.stopwatch = time
          })
          this.status = status
          this.trigger = trigger
          break
        case 'stop':
          stopStopWatch()
          this.status = status
          this.trigger = trigger
          this.times = data.times
          break
      }
    }
  }
})
