import { wampCall } from './wamp'

export const videoClient = {
  time: (type: string) => {
    return wampCall(type + '.review.video.time')
  },

  goto: (type: string, value: number) => {
    return wampCall(type + '.review.video.goto', [value])
  }
}
