<script setup lang="ts">
import {
  Cable,
  Home,
  Trophy,
  Settings,
  Bike,
  Timer,
  Unplug,
  Moon,
  Sun,
  MessageCircleMore
} from 'lucide-vue-next'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

import { RouterLink } from 'vue-router'

import { useThemeStore } from '@/stores/themeStore'
const themeStore = useThemeStore()

import { useModalStore } from '@/stores/modalStore'
const modalStore = useModalStore()

interface isWampConnectedProps {
  isWampConnected: boolean
}

const { isWampConnected = false } = defineProps<isWampConnectedProps>()

const openUpdateDynamrLocalModal = () => {
  modalStore.openUpdateDynamrLocalModal()
}
</script>

<template>
  <aside
    class="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r border-primary bg-main sm:flex"
  >
    <nav class="flex flex-col items-center gap-4 px-2 sm:py-5">
      <RouterLink
        to="/"
        class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary md:h-8 md:w-8"
        activeClass="bg-primary text-accent-foreground hover:text-black"
        exactActiveClass="bg-primary text-accent-foreground hover:text-black"
      >
        <Avatar>
          <AvatarImage src="/IJ_logo_lite.png" alt="IsJudging-logo" />
          <AvatarFallback>IJ</AvatarFallback>
        </Avatar>
      </RouterLink>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <RouterLink
              to="/"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary md:h-8 md:w-8"
              activeClass="bg-primary text-accent-foreground hover:text-black"
              exactActiveClass="bg-primary text-accent-foreground hover:text-black"
            >
              <Home class="h-5 w-5" />
              <span class="sr-only">Dashboard</span>
            </RouterLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Dashboard </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <!-- <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <RouterLink
              to="/competitions"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary md:h-8 md:w-8"
              activeClass="bg-primary text-accent-foreground hover:text-black"
              exactActiveClass="bg-primary text-accent-foreground hover:text-black"
            >
              <Trophy class="h-5 w-5" />
              <span class="sr-only">Competitions</span>
            </RouterLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Competitions </TooltipContent>
        </Tooltip>
      </TooltipProvider> -->
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <RouterLink
              :to="{ name: 'training' }"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary md:h-8 md:w-8"
              :class="{
                'bg-primary text-accent-foreground hover:text-black':
                  $route.name === 'training' || $route.meta.navActive
              }"
            >
              <Bike class="h-5 w-5" />
              <span class="sr-only">Training</span>
            </RouterLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Training </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </nav>

    <nav class="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
      <!-- <TooltipProvider :delay-duration="200" :skip-delay-duration="500">
        <Tooltip>
          <TooltipTrigger as-child>
            <RouterLink
              to="/status"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:bg-primary hover:text-primary hover:text-white md:h-8 md:w-8"
              activeClass="bg-primary text-accent-foreground hover:text-black"
              exactActiveClass="bg-primary text-accent-foreground hover:text-black"
            >
              <Timer class="h-5 w-5" />
              <span class="sr-only">Status</span>
            </RouterLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Status </TooltipContent>
        </Tooltip>
      </TooltipProvider> -->

      <TooltipProvider v-if="isWampConnected">
        <Tooltip>
          <TooltipTrigger as-child>
            <div
              href="#"
              class="flex h-9 w-9 items-center justify-center rounded-lg bg-green-500 transition-colors hover:text-foreground hover:text-white md:h-8 md:w-8"
              @click.prevent="openUpdateDynamrLocalModal"
            >
              <Cable class="h-5 w-5" />
              <span class="sr-only">Connected</span>
            </div>
          </TooltipTrigger>
          <TooltipContent side="right"> Judging System Connected </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider v-else>
        <Tooltip>
          <TooltipTrigger as-child>
            <div
              href="#"
              class="flex h-9 w-9 items-center justify-center rounded-lg bg-red-500 transition-colors hover:text-foreground hover:text-white md:h-8 md:w-8"
              @click.prevent="openUpdateDynamrLocalModal"
            >
              <Unplug class="h-5 w-5" />
              <span class="sr-only">Disconnected</span>
            </div>
          </TooltipTrigger>
          <TooltipContent side="right"> Judging System Disconnected </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <!-- <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <RouterLink
              to="/settings"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary md:h-8 md:w-8"
              activeClass="bg-primary text-accent-foreground hover:text-black"
              exactActiveClass="bg-primary text-accent-foreground hover:text-black"
            >
              <Settings class="h-5 w-5" />
              <span class="sr-only">Settings</span>
            </RouterLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Settings </TooltipContent>
        </Tooltip>
      </TooltipProvider> -->
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <RouterLink
              to="/contact"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary md:h-8 md:w-8"
              activeClass="bg-primary text-accent-foreground hover:text-black"
              exactActiveClass="bg-primary text-accent-foreground hover:text-black"
            >
              <MessageCircleMore class="h-5 w-5" />
              <span class="sr-only">Contact</span>
            </RouterLink>
          </TooltipTrigger>
          <TooltipContent side="right"> Contact </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger as-child>
            <div
              href="#"
              class="flex h-9 w-9 items-center justify-center rounded-lg text-main-foreground transition-colors hover:text-primary hover:text-white md:h-8 md:w-8"
              @click="themeStore.toggleTheme"
            >
              <Sun class="h-5 w-5" v-if="themeStore.isDark" />
              <Moon class="h-5 w-5" v-else />
              <span class="sr-only">Dark Mode</span>
            </div>
          </TooltipTrigger>
          <TooltipContent side="right"> Dark Mode </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </nav>
  </aside>
</template>
