<script setup lang="ts">
import { ref, computed } from 'vue'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { useDynamrLocalStore } from '@/stores/dynamrLocalStore'
import { useModalStore } from '@/stores/modalStore'
import notify from '@/services/notify'

const dynamrLocalStore = useDynamrLocalStore()
const modalStore = useModalStore()

const newDynamrLocal = ref(dynamrLocalStore.getDynamrLocal)

// const enableLocalCompetitions = computed({
//   get: () => dynamrLocalStore.getEnableLocalCompetitions,
//   set: (value) => {
//     dynamrLocalStore.setEnableLocalCompetitions(value)
//     notify(
//       'Local Competitions setting updated', 
//       value ? 'Enabled local competitions.' : 'Disabled local competitions.',
//       'success'
//     )
//   }
// })

const isValidIpAddress = (ipAddress: string) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)
}

const isValidDomainName = (domainName: string) => {
  return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(domainName)
}

const updateDynamrLocal = () => {
  if (newDynamrLocal.value === '' || newDynamrLocal.value === null) {
    return
  }

  // Check if the new dynamr local is a valid IP address, domain name, or localhost
  if (!isValidIpAddress(newDynamrLocal.value) && !isValidDomainName(newDynamrLocal.value) && newDynamrLocal.value !== 'localhost') {
    notify('Invalid Dynamr Local IP Address or Domain Name', 'Please enter a valid IP address or domain name', 'error')
    return
  }

  dynamrLocalStore.setDynamrLocal(newDynamrLocal.value)
  modalStore.closeUpdateDynamrLocalModal()
  notify('Dynamr Local updated', 'Please reload the page', 'success')
}

// const toggleEnableLocalCompetitions = () => {
//   if (enableLocalCompetitions.value === true) {
//     dynamrLocalStore.setEnableLocalCompetitions(false)
//     notify('Local Competitions setting updated', enableLocalCompetitions.value ? 'Enabled local competitions.' : 'Disabled local competitions.', 'success')
//   } else if (enableLocalCompetitions.value === false) {
//     dynamrLocalStore.setEnableLocalCompetitions(true)
//     notify('Local Competitions setting updated', enableLocalCompetitions.value ? 'Enabled local competitions.' : 'Disabled local competitions.', 'error')
//   }
// }

const closeModal = () => {
  modalStore.closeUpdateDynamrLocalModal()
}
</script>

<template>
  <div
    v-if="modalStore.isUpdateDynamrLocalModalOpen"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white dark:bg-gray-800 rounded-lg p-6 w-100">
      <h2 class="text-xl mb-4 text-gray-800 dark:text-gray-100">Update Dynamr Local IP Address</h2>
      
      <input
        v-model="newDynamrLocal"
        type="text"
        class="w-full border rounded px-3 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-primary"
        placeholder="Enter new value"
      />
      
      <p class="mt-2 mb-6 text-sm text-gray-500">
        Be sure to accept the new DynamR certificate 
        <a class="text-blue-500" :href="`https://${newDynamrLocal}:8443/`" target="_blank">here</a>
      </p>
      
      <!-- <div class="flex items-center mb-6">
        <Switch 
          :checked="enableLocalCompetitions"
          @update:checked="enableLocalCompetitions = $event"
        />
        <span class="ml-2 text-gray-700 dark:text-gray-300">Enable Local Competitions</span>
      </div> -->

      <div class="flex justify-end gap-2">
        <Button variant="secondary" @click="closeModal">Cancel</Button>
        <Button variant="default" @click="updateDynamrLocal" :disabled="newDynamrLocal === ''">Save</Button>
      </div>
    </div>
  </div>
</template>