import axios, { type AxiosInstance } from 'axios'

// https://back.competition.tunn3l.com/api.php?params[do]=getCompetition&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=c83b3112547922436f7ccdb48513bd630e523b07

// http://back.competition.tunn3l.com/api.php?params%5Bdo%5D=setCompetitionScores&params%5Btoken%5D=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params%5Bargs%5D%5Btoken_competition%5D=5bf24b4fb495c33485c35184fba8beba3de35b96&params%5Bargs%5D%5Bdiscipline_id%5D=36&params%5Bargs%5D%5Bround_id%5D=143&params%5Bargs%5D%5Bmatch_id%5D=0&params%5Bargs%5D%5Bteam_id%5D=100&params%5Bargs%5D%5Bscore%5D=110.123

// http://back.competition.tunn3l.com/api.php?params[do]=setCompetitionScores&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=5bf24b4fb495c33485c35184fba8beba3de35b96&params[args][discipline_id]=36&params[args][round_id]=143&params[args][match_id]=0&params[args][team_id]=100&params[args][score]=110.123

// Mettre un 'ring' en position 'snake' roud 1 équipe 101 :
// https://back.competition.tunn3l.com/api.php?params[do]=setCompetitionScores&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=5bf24b4fb495c33485c35184fba8beba3de35b96&params[args][competition_id]=6&params[args][discipline_id]=36&params[args][round_id]=133&params[args][match_id]=0&params[args][team_id]=100&params[args][judge_id]=0&params[args][draw_order]=2&params[args][score]=3&params[args][is_presentation]=0&params[args][comment]=ri

// Forcer un final_time différent du final_time calculé :
// http://back.competition.tunn3l.com/api.php?params[do]=setCompetitionScores&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=5bf24b4fb495c33485c35184fba8beba3de35b96&params[args][competition_id]=6&params[args][discipline_id]=36&params[args][round_id]=133&params[args][match_id]=0&params[args][team_id]=100&params[args][judge_id]=0&params[args][draw_order]=0&params[args][score]=99.33&params[args][is_presentation]=0&params[args][comment]=final_time

export default {
  getCompetition(provider_url: string, provider_token: string, token: string) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return apiClient.get(
      `api.php?params[do]=getCompetition&params[token]=${provider_token}&params[args][token_competition]=${token}`
    )
  },

  async uploadDynamicSpeedRawTime(
    provider_url: string,
    provider_token: string,
    token: string,
    competition_id: number,
    event_id: number,
    round_id: number,
    battle_id: number,
    team_id: number,
    judge_id: 0,
    draw_order: 0,
    score: number,
    is_presentation: 0,
    is_url: 0,
    comment: 'time'
  ) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    let payload = `api.php?params[do]=setCompetitionScores&params[token]=${provider_token}&params[args][token_competition]=${token}&params[args][competition_id]=${competition_id}&params[args][discipline_id]=${event_id}&params[args][round_id]=${round_id}&params[args][battle_id]=${battle_id}&params[args][team_id]=${team_id}&params[args][judge_id]=${judge_id}&params[args][draw_order]=${draw_order}&params[args][score]=${score}&params[args][is_presentation]=${is_presentation}&params[args][comment]=${comment}`

    const response = await apiClient.post(payload)
    const data = response.data
    if (data.errors.length > 0) {
      throw new Error(JSON.stringify(data.errors))
    }
    return data
  },

  // https://back.competition.tunn3l.com/api.php?params[do]=setCompetitionScores&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=5bf24b4fb495c33485c35184fba8beba3de35b96&params[args][competition_id]=6&params[args][discipline_id]=36&params[args][round_id]=133&params[args][battle_id]=0&params[args][team_id]=100&params[args][judge_id]=0&params[args][draw_order]=0&params[args][score]=99.999&params[args][is_presentation]=0&params[args][comment]=final_time
  async uploadDynamicSpeedFinalTime(
    provider_url: string,
    provider_token: string,
    token: string,
    competition_id: number,
    event_id: number,
    round_id: number,
    battle_id: number,
    team_id: number,
    judge_id: 0,
    draw_order: 0,
    score: number,
    is_presentation: 0,
    is_url: 0,
    comment: 'final_time'
  ) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    let payload = `api.php?params[do]=setCompetitionScores&params[token]=${provider_token}&params[args][token_competition]=${token}&params[args][competition_id]=${competition_id}&params[args][discipline_id]=${event_id}&params[args][round_id]=${round_id}&params[args][battle_id]=${battle_id}&params[args][team_id]=${team_id}&params[args][judge_id]=${judge_id}&params[args][draw_order]=${draw_order}&params[args][score]=${score}&params[args][is_presentation]=${is_presentation}&params[args][comment]=${comment}`

    const response = await apiClient.post(payload)
    const data = response.data
    if (data.errors.length > 0) {
      throw new Error(JSON.stringify(data.errors))
    }
    return data
  },

  // https://back.competition.tunn3l.com/api.php?params[do]=setCompetitionScores&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=5bf24b4fb495c33485c35184fba8beba3de35b96&params[args][competition_id]=6&params[args][discipline_id]=36&params[args][round_id]=133&params[args][battle_id]=0&params[args][team_id]=100&params[args][judge_id]=0&params[args][draw_order]=1&params[args][score]=3&params[args][is_presentation]=0&params[args][comment]=
  async applyBustDynamicSpeed(
    provider_url: string,
    provider_token: string,
    token: string,
    competition_id: number,
    event_id: number,
    round_id: number,
    battle_id: number,
    team_id: number,
    judge_id: 0,
    draw_order: number,
    score: number,
    is_presentation: 0,
    is_url: 0,
    comment: 'cl' | 'sl' | 'ri' | 'ex' | 'sy' | 'sk' | '0'
  ) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    let payload = `api.php?params[do]=setCompetitionScores&params[token]=${provider_token}&params[args][token_competition]=${token}&params[args][competition_id]=${competition_id}&params[args][discipline_id]=${event_id}&params[args][round_id]=${round_id}&params[args][battle_id]=${battle_id}&params[args][team_id]=${team_id}&params[args][judge_id]=${judge_id}&params[args][draw_order]=${draw_order}&params[args][score]=${score}&params[args][is_presentation]=${is_presentation}&params[args][comment]=${comment}`

    const response = await apiClient.post(payload)
    const data = response.data
    if (data.errors.length > 0) {
      throw new Error(JSON.stringify(data.errors))
    }
    return data
  },

  // https://back.competition.tunn3l.com/api.php?params[do]=setCompetitionScores&params[token]=992349dd22f4c036a88a1c65e7fc4a8db8a2f39b&params[args][token_competition]=5bf24b4fb495c33485c35184fba8beba3de35b96&params[args][competition_id]=6&params[args][discipline_id]=40&params[args][round_id]=137&params[args][battle_id]=0&params[args][team_id]=94&params[args][judge_id]=17&params[args][draw_order]=1&params[args][score]=1&params[args][is_presentation]=0&params[args][comment]=
  async uploadFSattempts(
    provider_url: string,
    provider_token: string,
    token: string,
    competition_id: number,
    event_id: number,
    round_id: number,
    battle_id: 0,
    team_id: number,
    judge_id: 0,
    draw_order: 0,
    score: number,
    is_presentation: 0,
    is_url: 0,
    comment: 'nb_attempts'
  ) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    let payload = `api.php?params[do]=setCompetitionScores&params[token]=${provider_token}&params[args][token_competition]=${token}&params[args][competition_id]=${competition_id}&params[args][discipline_id]=${event_id}&params[args][round_id]=${round_id}&params[args][battle_id]=${battle_id}&params[args][team_id]=${team_id}&params[args][judge_id]=${judge_id}&params[args][draw_order]=${draw_order}&params[args][score]=${score}&params[args][is_presentation]=${is_presentation}&params[args][comment]=${comment}`

    const response = await apiClient.post(payload)
    const data = response.data
    if (data.errors.length > 0) {
      throw new Error(JSON.stringify(data.errors))
    }
    return data
  },

  async applyBustFS(
    provider_url: string,
    provider_token: string,
    token: string,
    competition_id: number,
    event_id: number,
    round_id: number,
    battle_id: 0,
    team_id: number,
    judge_id: number,
    draw_order: number,
    score: 1,
    is_presentation: 0,
    is_url: 0,
    comment: 'f' | 's' | 'i' | 'g' | 'o'
  ) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    let payload = `api.php?params[do]=setCompetitionScores&params[token]=${provider_token}&params[args][token_competition]=${token}&params[args][competition_id]=${competition_id}&params[args][discipline_id]=${event_id}&params[args][round_id]=${round_id}&params[args][battle_id]=${battle_id}&params[args][team_id]=${team_id}&params[args][judge_id]=${judge_id}&params[args][draw_order]=${draw_order}&params[args][score]=${score}&params[args][is_presentation]=${is_presentation}&params[args][comment]=${comment}`

    const response = await apiClient.post(payload)
    const data = response.data
    if (data.errors.length > 0) {
      throw new Error(JSON.stringify(data.errors))
    }
    return data
  },

  async removeBustFS(
    provider_url: string,
    provider_token: string,
    token: string,
    competition_id: number,
    event_id: number,
    round_id: number,
    battle_id: 0,
    team_id: number,
    judge_id: number,
    draw_order: number,
    score: 0,
    is_presentation: 0,
    is_url: 0,
    comment: 0
  ) {
    const apiClient: AxiosInstance = axios.create({
      baseURL: provider_url + '/',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    let payload = `api.php?params[do]=setCompetitionScores&params[token]=${provider_token}&params[args][token_competition]=${token}&params[args][competition_id]=${competition_id}&params[args][discipline_id]=${event_id}&params[args][round_id]=${round_id}&params[args][battle_id]=${battle_id}&params[args][team_id]=${team_id}&params[args][judge_id]=${judge_id}&params[args][draw_order]=${draw_order}&params[args][score]=${score}&params[args][is_presentation]=${is_presentation}&params[args][comment]=${comment}`

    const response = await apiClient.post(payload)
    const data = response.data
    if (data.errors.length > 0) {
      throw new Error(JSON.stringify(data.errors))
    }
    return data
  },
}
