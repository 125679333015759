import { useToast } from '@/components/ui/toast/use-toast'

const { toast } = useToast()

export default function notify(
  message: string,
  description?: string,
  type?: 'info' | 'error' | 'success'
) {
  toast({
    title: message,
    description: description,
    variant: type || 'info'
  })
}
