// stores/modalStore.ts
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useModalStore = defineStore('modalStore', () => {
  const isUpdateDynamrLocalModalOpen = ref(false)

  const openUpdateDynamrLocalModal = () => {
    isUpdateDynamrLocalModalOpen.value = true
  }

  const closeUpdateDynamrLocalModal = () => {
    isUpdateDynamrLocalModalOpen.value = false
  }

  return {
    isUpdateDynamrLocalModalOpen,
    openUpdateDynamrLocalModal,
    closeUpdateDynamrLocalModal
  }
})