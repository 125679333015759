// utils/competitionManager.ts
import type {
  Competition,
  Round,
  Flight,
  Team,
  Battle,
  ExtendedFlight
} from '@/interfaces/competition'

export class CompetitionManager {
  public rawCompetition: Competition
  private teams: Map<number, Team> = new Map()
  private flightMap: Map<string, ExtendedFlight> = new Map()

  constructor(competition_datas: Competition) {
    this.rawCompetition = competition_datas
    this.initializeData(competition_datas)
    // this.calculateEventsResults(competition_datas)
  }

  private initializeData(competition_datas: Competition) {
    competition_datas.events.forEach((event) => {
      // On alimente la map des teams
      event.teams.forEach((team) => {
        this.teams.set(team.id, team)
      })
      // On alimente la map des flights
      event.rounds.forEach((round) => {
        round.flights.forEach((flight) => {
          this.addFlight(
            flight,
            event.id,
            round.id,
            null,
            null,
            round.number,
            null,
            null,
            event.discipline,
            round.flight_type,
            round.configuration
          )
        })
        if (round.battles) {
          this.processBattles(event.discipline, round.battles, event.id, round.id, round.number)
        }
      })
    })
  }

  private processSubBattles(
    discipline: string,
    sub_battles: Battle[],
    eventId: number,
    roundId: number,
    roundNumber: number,
    battleId: number,
    battleNumber: number
  ) {
    sub_battles.forEach((sub_battle) => {
      sub_battle.flights.forEach((flight) => {
        this.addFlight(
          flight,
          eventId,
          roundId,
          battleId,
          sub_battle.id,
          roundNumber,
          battleNumber,
          sub_battle.number,
          discipline,
          sub_battle.flight_type,
          sub_battle.configuration
        )
      })
    })
  }

  private processBattles(
    discipline: string,
    battles: Battle[],
    eventId: number,
    roundId: number,
    roundNumber: number
  ) {
    battles.forEach((battle) => {
      battle.flights.forEach((flight) => {
        this.addFlight(
          flight,
          eventId,
          roundId,
          battle.id,
          null,
          roundNumber,
          battle.number,
          null,
          discipline,
          battle.flight_type,
          battle.configuration
        )
      })
      if (battle.sub_battles) {
        this.processSubBattles(
          discipline,
          battle.sub_battles,
          eventId,
          roundId,
          roundNumber,
          battle.id,
          battle.number
        )
      }
    })
  }

  private addFlight(
    flight: Flight,
    eventId: number,
    roundId: number,
    battleId: number | null,
    subBattleId: number | null,
    roundNumber: number,
    battleNumber: number | null,
    subBattleNumber: number | null,
    discipline: string,
    flight_type: string,
    configuration: any
  ) {
    const extendedFlight: ExtendedFlight = {
      ...flight,
      competition_id: this.getCompetitionData().id,
      event_id: eventId,
      round_number: roundNumber,
      battle_number: battleNumber ?? null,
      // team_id: flight.team ? flight.team.id : null,
      discipline: discipline,
      flight_type: flight_type,
      configuration: configuration
    }
    this.flightMap.set(flight.id.toString(), extendedFlight)
  }

  public getNonTournamentRounds(eventId: number): Round[] {
    const event = this.rawCompetition.events.find((e) => e.id === eventId)
    if (!event) return []
    return event.rounds.filter((round) => !round.is_tournament)
  }

  public getTournamentRounds(eventId: number): Round[] {
    const event = this.rawCompetition.events.find((e) => e.id === eventId)
    if (!event) return []
    return event.rounds.filter((round) => round.is_tournament)
  }

  public getCompetitionData(): Competition {
    return {
      ...this.rawCompetition
    }
  }

  public getFlightInfo(flightId: string): ExtendedFlight | undefined {
    return this.flightMap.get(flightId)
  }

  public getTeam(teamId: number): Team | undefined {
    return this.teams.get(teamId)
  }
}
